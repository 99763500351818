import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuditConfigLocator, IAuditConfigLocator } from '../../core/audit/audit.config';
import despesaAudit from './despesa.audit';
import contaCorrenteAudit from './conta-corrente.audit';
import naturezaAudit from './natureza.audit';
import objetoDespesaAudit from './objeto-despesa.audit';
import statusAudit from './status.audit';
import acaoOrcamentariaAudit from './acao-orcamentaria.audit';
import formaConcessaoAudit from './forma-concessao.audit';
import portariaAudit from './portaria.audit';
import suprimentoAudit from './suprimento.audit';

@NgModule()
export class AuditConfigModule {
  constructor(@Optional() @SkipSelf() parentModule?: AuditConfigModule) {
    if (parentModule) {
      throw new Error('Configuração do módulo de auditoria já foi carregado.');
    }
  }

  static forRoot(): ModuleWithProviders<AuditConfigModule> {
    AuditConfigLocator.getInstance().register(contaCorrenteAudit);
    AuditConfigLocator.getInstance().register(despesaAudit);
    AuditConfigLocator.getInstance().register(naturezaAudit);
    AuditConfigLocator.getInstance().register(objetoDespesaAudit);
    AuditConfigLocator.getInstance().register(statusAudit);
    AuditConfigLocator.getInstance().register(acaoOrcamentariaAudit);
    AuditConfigLocator.getInstance().register(formaConcessaoAudit);
    AuditConfigLocator.getInstance().register(portariaAudit);
    AuditConfigLocator.getInstance().register(suprimentoAudit);
    return {
      ngModule: AuditConfigModule,
      providers: [{ provide: IAuditConfigLocator, useValue: AuditConfigLocator.getInstance() }]
    };
  }
}
