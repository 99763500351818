import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { Status } from '../status/status.model';

const statusAudit: ResourceConfig = {
  api: 'status',
  path: 'status',
  entity: Status.name,
  label: 'Status',
  translateKey: 'status',
  showInPage: false,
  labelTemplate: `<u>Status '{descricao}'</u>`,
  columns: [
    { field: 'descricao', header: 'field.descricao', isDefaultVisible: true },
    { field: 'impedeNovoSup', header: 'field.impedeNovoSup', isDefaultVisible: true },
    {
      field: 'dataCadastro',
      header: 'field.dataCadastro',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy HH:mm',
      formatOption: 'DATETIME'
    },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default statusAudit;
