import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { Suprimento } from '../suprimento/suprimento.model';

const suprimentoAudit: ResourceConfig = {
  api: 'suprimentos',
  path: 'suprimentos',
  entity: Suprimento.name,
  label: 'Suprimento',
  translateKey: 'suprimento',
  showInPage: false,
  labelTemplate: `<u>Suprimento '{ano}'</u>`,
  columns: [
    { field: 'ano', header: 'field.ano', isDefaultVisible: true },
    { field: 'controleSequencial', header: 'field.controleSequencial', isDefaultVisible: true },
    { field: 'acaoOrcamentaria.descricao', header: 'field.acaoOrcamentaria', isDefaultVisible: true },
    { field: 'suprido.nome', header: 'field.suprido', isDefaultVisible: true },
    { field: 'prazoAplic', header: 'field.prazoAplic', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'portarias', header: 'field.portarias', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'codigoFechamento', header: 'field.codigoFechamento', isDefaultVisible: true },
    {
      field: 'valorTotalConcedido',
      header: 'field.valorTotalConcedido',
      translateKey: 'movimentar',
      isDefaultVisible: true,
      isMoney: true,
      formatOption: 'MONETARY'
    },
    { field: 'valorBaixa', header: 'field.valorBaixa', translateKey: 'movimentar', isDefaultVisible: true, isMoney: true },
    { field: 'classificacao', header: 'field.classificacao', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'saldo', header: 'field.saldo', translateKey: 'movimentar', isDefaultVisible: true, isMoney: true },
    { field: 'reembolso', header: 'field.reembolso', translateKey: 'movimentar', isDefaultVisible: true, isMoney: true },
    { field: 'justificativa', header: 'field.justificativa', translateKey: 'movimentar', isDefaultVisible: true },
    {
      field: 'justificativaAlteracaoPrazo',
      header: 'field.justificativaAlteracaoPrazoAudit',
      translateKey: 'movimentar',
      isDefaultVisible: true
    },
    { field: 'parecer', header: 'field.parecer', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'observacao', header: 'field.observacao', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'agencia.numAgencia', header: 'field.agencia', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'sadp', header: 'field.sadp', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'formaConcessao.descricao', header: 'field.formaConcessao', isDefaultVisible: true },
    { field: 'dataDisp', header: 'field.dataDisp', translateKey: 'movimentar', isDefaultVisible: true, isDate: true, mask: 'dd/MM/yyyy' },
    {
      field: 'dataHomologacao',
      header: 'field.dataHomologacao',
      translateKey: 'movimentar',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy'
    },
    { field: 'declaracaoPresidente', header: 'field.declaracaoPresidente', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'inteiroTeor', header: 'field.inteiroTeor', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'prazoPrestConta', header: 'field.prazoPrestConta', translateKey: 'movimentar', isDefaultVisible: true },
    {
      field: 'dataLibera',
      header: 'field.dataLiberacao',
      translateKey: 'movimentar',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy'
    },
    {
      field: 'dataCancelamento',
      header: 'field.dataCancelamento',
      translateKey: 'movimentar',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy'
    },
    { field: 'origemLocal.localidade', header: 'field.origemLocal', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'numConta', header: 'field.numConta', translateKey: 'movimentar', isDefaultVisible: true },
    { field: 'ptres', header: 'field.ano', isDefaultVisible: true },
    { field: 'portaria.descricao', header: 'field.portaria', translateKey: 'movimentar', isDefaultVisible: true },
    {
      field: 'listaValorNatureza',
      header: 'field.listaValorNatureza',
      isDefaultVisible: true,
      isTable: true,
      isNotExport: true,
      tableColumns: [
        { field: 'natureza.codigo', header: 'suprimento.field.codigo', isDefaultVisible: true },
        { field: 'natureza.descricao', header: 'suprimento.field.natureza', isDefaultVisible: true },
        { field: 'valorDisponivel', header: 'suprimento.field.valorDisponivel', isDefaultVisible: true, isMoney: true },
        { field: 'notaEmpenho', header: 'suprimento.field.notaEmpenho', isDefaultVisible: true }
      ]
    },
    { field: 'status.descricao', header: 'field.status', isDefaultVisible: true },
    { field: 'homologa', header: 'field.homologa', isDefaultVisible: true },
    {
      field: 'numeroDocumentoContabilizacao',
      header: 'field.numeroDocumentoContabilizacao',
      translateKey: 'movimentar',
      isDefaultVisible: true
    }
  ]
};

export default suprimentoAudit;
