import { DateMaskDirective } from './directive/date-mask.directive';
import { SimNaoPipe } from './pipes/sim-nao.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { DebounceClickDirective } from './directive/debounce.directive';
import { AppTranslatePipe } from './pipes/app-translate.pipe';
import { CnpjCpfPipe } from './pipes/cnpj.pipe';
import { EmptyDataPipe } from './pipes/empty-data.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { ThousandFormatPipe } from './pipes/thousand-format';
import { UrlFileStoragePipe } from './pipes/url-filestorage.pipe';
import { FileUploadComponent } from './shared/upload/file-upload.component';
import { ValidatorComponent } from './shared/validator/validator.component';
import { ValidatorClassDirective } from './directive/validator-class.directive';
import { HasPermissionDirective } from './directive/has-permissions.directive';
import { ValidationFormFieldComponent } from './shared/validation-search-field/validation-form-field.component';
import { PhoneFormatPipe } from './pipes/phone.pipe';
import { HasPermissionPipe } from './pipes/has-permission.pipe';
import { AuditRouterDirective } from '../audit/directive/router.directive';
import { fieldTruncateEllipsisPipe } from './pipes/field-truncate-ellipsis.pipe';
import { TipoSuprimentoPipe } from './pipes/tipo-suprimento-pipe.pipe';

@NgModule({
  declarations: [
    FileUploadComponent,
    ValidatorComponent,
    ValidationFormFieldComponent,
    DebounceClickDirective,
    DateMaskDirective,
    ValidatorClassDirective,
    HasPermissionDirective,
    AppTranslatePipe,
    CnpjCpfPipe,
    PhoneFormatPipe,
    SimNaoPipe,
    EmptyDataPipe,
    RelativeTimePipe,
    ThousandFormatPipe,
    UrlFileStoragePipe,
    HasPermissionPipe,
    AuditRouterDirective,
    fieldTruncateEllipsisPipe,
    TipoSuprimentoPipe
  ],
  imports: [CommonModule, FormsModule, TranslateModule, RouterModule, AngularSvgIconModule, InputTextModule, TooltipModule, ButtonModule],
  exports: [
    FileUploadComponent,
    ValidatorComponent,
    ValidationFormFieldComponent,
    DebounceClickDirective,
    DateMaskDirective,
    ValidatorClassDirective,
    HasPermissionDirective,
    AppTranslatePipe,
    CnpjCpfPipe,
    SimNaoPipe,
    PhoneFormatPipe,
    EmptyDataPipe,
    RelativeTimePipe,
    ThousandFormatPipe,
    UrlFileStoragePipe,
    HasPermissionPipe,
    AuditRouterDirective,
    fieldTruncateEllipsisPipe,
    TipoSuprimentoPipe
  ]
})
export class UIModule {}
