import { Pipe, PipeTransform } from '@angular/core';
import { TipoSuprimento } from 'src/app/domain/tipo-suprimento/tipo-suprimento';

@Pipe({
  name: 'tipoSuprimentoPipe'
})
export class TipoSuprimentoPipe implements PipeTransform {
  transform(value: string) {
    if (value === null) {
      return '';
    }

    if (value === TipoSuprimento.GERAL) {
      return TipoSuprimento.GERAL_STRING;
    } else if (value === TipoSuprimento.PRIMEIROTURNO) {
      return TipoSuprimento.PRIMEIROTURNO_STRING;
    } else if (value === TipoSuprimento.SEGUNDOTURNO) {
      return TipoSuprimento.SEGUNDOTURNO_STRING;
    } else {
      return '';
    }
  }
}
