/**
 * Classe abstrata para configurar a injeção de dependência da classe de configuração.
 */
import { ResourceConfig } from './model/resource-config.model';

export abstract class IAuditConfigLocator {
  abstract register(resourceConfig: ResourceConfig): ResourceConfig;

  abstract get(resource: string): ResourceConfig;

  abstract getAllAuditedEntities(): Array<ResourceConfig>;
}

/**
 * Classe singleton para manter a lista de configurações providas por decoradores.
 */
export class AuditConfigLocator implements IAuditConfigLocator {
  readonly config: Map<string, ResourceConfig>;
  readonly allEntries: Array<ResourceConfig>;
  private static instance: AuditConfigLocator;

  private constructor() {
    this.config = new Map<string, ResourceConfig>();
    this.allEntries = new Array<ResourceConfig>();
  }

  register(resourceConfig: ResourceConfig): ResourceConfig {
    const resourceConfigFreeze = Object.freeze(resourceConfig);
    this.config.set(resourceConfig.entity, resourceConfigFreeze);
    this.config.set(resourceConfig.path, resourceConfigFreeze);
    this.allEntries.push(resourceConfigFreeze);
    return resourceConfigFreeze;
  }

  get(resource: string): ResourceConfig {
    if (this.config?.has(resource)) {
      return this.config?.get(resource);
    }
    return null;
  }

  getAllAuditedEntities(): Array<ResourceConfig> {
    return this.allEntries ?? [];
  }

  public static getInstance(): AuditConfigLocator {
    if (!AuditConfigLocator.instance) {
      AuditConfigLocator.instance = new AuditConfigLocator();
    }
    return AuditConfigLocator.instance;
  }
}
