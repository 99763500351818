import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from './app.main.component';
import { AuthUser } from '../../core/auth/model/user.model';
import { AuthenticationService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { AuthLogout } from '../../core/auth/model/logout.model';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topbar',
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-left">
        <a
          href="#"
          class="topbar-menu-button"
          (click)="appMain.onMenuButtonClick($event)"
          *ngIf="appMain.isOverlay() || appMain.isMobile()"
        >
          <i class="pi pi-bars"></i>
        </a>

        <a href="#" class="logo">
          <img [src]="'assets/layout/images/logo-' + (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" />
        </a>

        <a href="#">
          <img [src]="'assets/layout/images/appname-' + (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name" />
        </a>
      </div>

      <app-menu></app-menu>

      <div class="layout-topbar-right">
        <ul class="layout-topbar-right-items">
          <li #profile class="profile-item" [ngClass]="{ 'active-topmenuitem': appMain.activeTopbarItem === profile }">
            <a href="#" (click)="appMain.onTopbarItemClick($event, profile)">
              <img src="assets/layout/images/profile-image.png" />
            </a>
            <ul class="fadeInDown">
              <a href="#" (click)="emitLogout($event)">
                <i class="pi pi-fw pi-sign-out"></i>
                <span>{{ 'action.logout' | appTranslate: 'login' }}</span>
              </a>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  `
})
export class AppTopbarComponent implements OnInit {
  activeItem: number;
  loggedUser: AuthUser;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public app: AppComponent,
    public appMain: AppMainComponent,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.authService.loggedUser();
  }

  logout(): void {
    this.authService.logout().subscribe((authLogout: AuthLogout) => {
      if (authLogout != null && authLogout.redirect && authLogout.redirectUrl) {
        window.location.href = authLogout.redirectUrl;
      } else {
        this.router.navigate(['account/login']).then();
      }
    });
  }

  emitLogout(event: Event): void {
    event.preventDefault();

    this.confirmationService.confirm({
      message: this.translateService.instant(`login.label.logout`.toUpperCase()),
      header: this.translateService.instant('login.title.logout'.toUpperCase()),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.logout();
      }
    });
  }
}
