export enum TipoSuprimento {
  GERAL = 'GERAL',
  TODOS = 'TODOS',
  PRIMEIROTURNO = 'PRIMEIROTURNO',
  SEGUNDOTURNO = 'SEGUNDOTURNO',
  GERAL_STRING = 'Geral',
  TODOS_STRING = 'Todos',
  PRIMEIROTURNO_STRING = '1° TURNO',
  SEGUNDOTURNO_STRING = '2° TURNO'
}
