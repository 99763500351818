import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customizacao } from 'src/app/domain/customizacao/customizacao.model';
import { Preferencia } from 'src/app/domain/preferencia/preferencia.model';
import { BaseService } from '../domain/base.service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService extends BaseService<Customizacao, string> {
  private localStorageCustomizationKey = 'layout_preferences';

  preferencia: Preferencia;
  horizontalMenu: boolean;
  darkMode: boolean;
  menuColorMode: string;
  menuColor: string;
  themeColor: string;
  layoutColor: string;
  ripple: boolean;
  inputStyle: string;

  lightColors = [
    { name: 'Blue', file: 'blue', image: 'blue.svg' },
    { name: 'Blue-TRE-MT', file: 'blue-tremt', image: 'blue.svg' },
    { name: 'Green', file: 'green', image: 'green.svg' },
    { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
    { name: 'Purple', file: 'purple', image: 'purple.svg' },
    { name: 'Orange', file: 'orange', image: 'orange.svg' },
    { name: 'Teal', file: 'teal', image: 'teal.svg' },
    { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
    { name: 'Lime', file: 'lime', image: 'lime.svg' },
    { name: 'Brown', file: 'brown', image: 'brown.svg' },
    { name: 'Red', file: 'red', image: 'red.svg' },
    { name: 'Indigo', file: 'indigo', image: 'indigo.svg' }
  ];
  darkColors = [
    { name: 'Blue', file: 'blue', image: 'blue.svg' },
    { name: 'Blue-TRE-MT', file: 'blue-tremt', image: 'blue.svg' },
    { name: 'Green', file: 'green', image: 'green.svg' },
    { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
    { name: 'Purple', file: 'purple', image: 'purple.svg' },
    { name: 'Orange', file: 'orange', image: 'orange.svg' },
    { name: 'Teal', file: 'teal', image: 'teal.svg' },
    { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
    { name: 'Lime', file: 'lime', image: 'lime.svg' },
    { name: 'Brown', file: 'brown', image: 'brown.svg' },
    { name: 'Red', file: 'red', image: 'red.svg' },
    { name: 'Indigo', file: 'indigo', image: 'indigo.svg' }
  ];
  customColors = [
    { name: 'Chile', file: 'chile', image: 'chile.png' },
    { name: 'Naples', file: 'naples', image: 'naples.png' },
    { name: 'Georgia', file: 'georgia', image: 'georgia.png' },
    { name: 'Infinity', file: 'infinity', image: 'infinity.png' },
    { name: 'Chicago', file: 'chicago', image: 'chicago.png' },
    { name: 'Majesty', file: 'majesty', image: 'majesty.png' },
    { name: 'Fish', file: 'fish', image: 'fish.png' },
    { name: 'Dawn', file: 'dawn', image: 'dawn.png' },
    { name: 'Record', file: 'record', image: 'record.png' },
    { name: 'Pool', file: 'pool', image: 'pool.png' },
    { name: 'Metal', file: 'metal', image: 'metal.png' },
    { name: 'China', file: 'china', image: 'china.png' },
    { name: 'Table', file: 'table', image: 'table.png' },
    { name: 'Panorama', file: 'panorama', image: 'panorama.png' },
    { name: 'Barcelona', file: 'barcelona', image: 'barcelona.png' },
    { name: 'Underwater', file: 'underwater', image: 'underwater.png' },
    { name: 'Symmetry', file: 'symmetry', image: 'symmetry.png' },
    { name: 'Rain', file: 'rain', image: 'rain.png' },
    { name: 'Utah', file: 'utah', image: 'utah.png' },
    { name: 'Wave', file: 'wave', image: 'wave.png' },
    { name: 'Flora', file: 'flora', image: 'flora.png' },
    { name: 'Speed', file: 'speed', image: 'speed.png' },
    { name: 'Canopy', file: 'canopy', image: 'canopy.png' },
    { name: 'SanPaolo', file: 'sanpaolo', image: 'sanpaolo.png' },
    { name: 'Basketball', file: 'basketball', image: 'basketball.png' },
    { name: 'Misty', file: 'misty', image: 'misty.png' },
    { name: 'Summit', file: 'summit', image: 'summit.png' },
    { name: 'Wall', file: 'wall', image: 'wall.png' },
    { name: 'Ferris', file: 'ferris', image: 'ferris.png' },
    { name: 'Ship', file: 'ship', image: 'ship.png' },
    { name: 'NY', file: 'ny', image: 'ny.png' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.png' },
    { name: 'Violet', file: 'violet', image: 'violet.png' },
    { name: 'Red', file: 'red', image: 'red.png' },
    { name: 'Blue', file: 'blue', image: 'blue.png' },
    { name: 'Porsuk', file: 'porsuk', image: 'porsuk.png' },
    { name: 'Pink', file: 'pink', image: 'pink.png' },
    { name: 'Purple', file: 'purple', image: 'purple.png' },
    { name: 'Orange', file: 'orange', image: 'orange.png' }
  ];
  menuColors = [{ name: 'light' }, { name: 'custom' }, { name: 'dark' }];
  selectedColorOptions = this.lightColors;
  componentThemes = [
    { name: 'Blue', file: 'blue', image: 'blue.svg' },
    { name: 'Blue-TRE-MT', file: 'blue-tremt', image: 'blue.svg' },
    { name: 'Green', file: 'green', image: 'green.svg' },
    { name: 'Yellow', file: 'yellow', image: 'yellow.svg' },
    { name: 'Cyan', file: 'cyan', image: 'cyan.svg' },
    { name: 'Purple', file: 'purple', image: 'purple.svg' },
    { name: 'Orange', file: 'orange', image: 'orange.svg' },
    { name: 'Teal', file: 'teal', image: 'teal.svg' },
    { name: 'Magenta', file: 'magenta', image: 'magenta.svg' },
    { name: 'Lime', file: 'lime', image: 'lime.svg' },
    { name: 'Brown', file: 'brown', image: 'brown.svg' },
    { name: 'Red', file: 'red', image: 'red.svg' },
    { name: 'Indigo', file: 'indigo', image: 'indigo.svg' }
  ];

  constructor(http: HttpClient, envService: EnvService) {
    super(http, envService, 'PreferenciaUsuario');
  }

  get(): Observable<Customizacao> {
    return this.http.get<Customizacao>(`${this.getBaseUrl()}`);
  }

  update(entity: Customizacao): Observable<Customizacao> {
    return this.http.put<Customizacao>(`${this.getBaseUrl()}`, {
      ...entity
    });
  }

  armazenaPreferenciasNoLocalStorage(preference: string): void {
    const preferenceString = JSON.stringify(preference);
    localStorage.setItem(this.localStorageCustomizationKey, preferenceString);
    this.loadPreference();
  }

  criarNovaCustomizacao(customization: Customizacao): void {
    this.create(customization).subscribe(() => {
      this.armazenaPreferenciasNoLocalStorage(customization.preferenciaJson);
    });
  }

  atualizaCustomizacao(customization: Customizacao): void {
    this.update(customization).subscribe(() => {
      this.armazenaPreferenciasNoLocalStorage(customization.preferenciaJson);
    });
  }

  removePreferenciasDoLocalStorage(): void {
    localStorage.removeItem(this.localStorageCustomizationKey);
  }

  async loadPreference(): Promise<void> {
    //FIXME this.padraoClaroOuEscuro(false);
    /*try {
      const preferencia = JSON.parse(this.recuperaPreferenciasNoLocalStorage());
      if (preferencia != null) {
        await this.customize(preferencia);
        await this.changeComponentTheme(preferencia.themeColor);
      } else {
        this.padraoClaroOuEscuro(false);
      }
    } catch (e) {
      this.padraoClaroOuEscuro(false);
    }*/
  }

  private recuperaPreferenciasNoLocalStorage(): string {
    try {
      const preferenceString = localStorage.getItem(this.localStorageCustomizationKey);
      if (preferenceString != null) {
        return JSON.parse(preferenceString);
      }
    } catch (e) {
      return '{}';
    }
  }

  async customize(preferencia: Preferencia): Promise<void> {
    this.darkMode = preferencia.darkMode;
    if (preferencia.darkMode === true) {
      this.selectedColorOptions = this.darkColors;
      this.menuColorMode = preferencia.menuColorMode;
      this.menuColor = preferencia.menuColor;
      this.inputStyle = preferencia.inputStyle;
      this.layoutColor = preferencia.layoutColor;
      this.ripple = preferencia.ripple;
      await this.changeLightDarkLayout('layout-css', preferencia.layoutColor, 'layout-dark').finally(async () => {
        await this.changeLightDarkTheme('theme-css', 'theme-dark');
      });
    } else {
      this.selectedColorOptions = this.lightColors;
      this.menuColorMode = preferencia.menuColorMode;
      this.menuColor = preferencia.menuColor;
      this.inputStyle = preferencia.inputStyle;
      this.layoutColor = preferencia.layoutColor;
      this.ripple = preferencia.ripple;
      await this.changeLightDarkLayout('layout-css', preferencia.layoutColor, 'layout-light').finally(async () => {
        await this.changeLightDarkTheme('theme-css', 'theme-light');
      });
    }
  }

  padraoClaroOuEscuro(mode): void {
    this.darkMode = mode;
    if (mode === true) {
      this.menuColorMode = 'dark';
      this.menuColor = 'layout-menu-dark';
      this.selectedColorOptions = this.darkColors;
      this.layoutColor = 'blue';
      this.themeColor = 'blue';
      this.ripple = true;
      this.inputStyle = 'outlined';
      this.changeLightDarkLayout('layout-css', 'blue', 'layout-dark').finally(() => {
        this.changeLightDarkTheme('theme-css', 'theme-dark');
      });
    } else {
      this.menuColorMode = 'light';
      this.menuColor = 'layout-menu-light';
      this.selectedColorOptions = this.lightColors;
      this.layoutColor = 'blue';
      this.themeColor = 'blue';
      this.ripple = true;
      this.inputStyle = 'outlined';
      this.changeLightDarkLayout('layout-css', 'blue', 'layout-light').then(() => {
        this.changeLightDarkTheme('theme-css', 'theme-light');
      });
    }
  }

  async changeLightDarkTheme(id, value): Promise<void> {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = value + '.css';
    const newURL = urlTokens.join('/');
    await this.replaceLink(element, newURL);
  }

  changeLightDarkLayout(id, color, mode): Promise<void> {
    return new Promise<void>(resolve => {
      const element = document.getElementById(id);
      const urlTokens = element.getAttribute('href').split('/');
      urlTokens[urlTokens.length - 2] = color;
      urlTokens[urlTokens.length - 1] = mode + '.css';
      const newURL = urlTokens.join('/');
      this.replaceLink(element, newURL);
      resolve();
    });
  }

  changeMenuToHorizontal(event, mode): void {
    this.horizontalMenu = mode;
    event.preventDefault();
  }

  changeMenuColor(event, mode): void {
    this.menuColorMode = mode;
    if (mode !== 'custom') {
      this.menuColor = 'layout-menu-' + mode;
      if (mode === 'dark') {
        this.selectedColorOptions = this.darkColors;
        this.layoutColor = this.selectedColorOptions[0].file;
        this.changeStyleSheetsColor('layout-css', this.selectedColorOptions[0].file);
      } else {
        this.selectedColorOptions = this.lightColors;
        this.layoutColor = this.selectedColorOptions[0].file;
        this.changeStyleSheetsColor('layout-css', this.selectedColorOptions[0].file);
      }
    } else {
      this.menuColor = 'layout-menu-' + this.customColors[0].file;
      this.selectedColorOptions = this.customColors;
    }
    event.preventDefault();
  }

  changeMenuTheme(event, color): void {
    if (this.menuColorMode !== 'custom') {
      this.changeStyleSheetsColor('layout-css', color);
      this.layoutColor = color;
    } else {
      this.menuColor = 'layout-menu-' + color;
    }
    event.preventDefault();
  }

  changeComponentTheme(color: string): void {
    this.themeColor = color;
    this.changeStyleSheetsColor('theme-css', color);
  }

  async changeStyleSheetsColor(id, value): Promise<void> {
    const element = document.getElementById(id);
    const urlTokens = await element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 2] = value;
    const newURL = await urlTokens.join('/');
    await this.replaceLink(element, newURL);
  }

  replaceLink(linkElement, href): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.isIE()) {
        linkElement.setAttribute('href', href);
      } else {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);
        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');
        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
        cloneLinkElement.addEventListener('load', async () => {
          await linkElement.remove();
          await cloneLinkElement.setAttribute('id', id);
          resolve();
        });
      }
    });
  }

  isIE(): boolean {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  saveCustomization(): void {
    this.preferencia = JSON.parse(this.recuperaPreferenciasNoLocalStorage());
    const armazemTemporarioCustomizacao: any = {
      ripple: this.ripple,
      horizontalMenu: this.horizontalMenu,
      darkMode: this.darkMode,
      menuColor: this.menuColor,
      themeColor: this.themeColor,
      layoutColor: this.layoutColor,
      menuColorMode: this.menuColorMode,
      inputStyle: this.inputStyle
    };
    const customization: Customizacao = new Customizacao();
    customization.preferenciaJson = JSON.stringify(armazemTemporarioCustomizacao);
    if (this.preferencia) {
      this.atualizaCustomizacao(customization);
    } else {
      this.criarNovaCustomizacao(customization);
    }
  }
}
