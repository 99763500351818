import { ApiBlobResponse } from './../api/api-blob-response';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { AuthenticationService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { ApiResponse } from '../api/apiResponse';
import { AuthUser } from '../auth/model/user.model';
import { AlertService } from '../ui/notifications/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private authenticationService: AuthenticationService, private alertService: AlertService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        switch (error.status) {
          case 400:
            return this.handle400Error(error);
          case 403:
            return this.handle403Error(error);
          case 401:
            return this.handle401Error(request, next, error);
          case 500:
            return this.handle500Error(error);
          default:
            return throwError(error);
        }
      })
    );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, httpError) {
    if (!this.isRefreshingToken && httpError && httpError.status === 401 && httpError.error && httpError.error.error === 'invalid_grant') {
      return throwError(httpError);
    }

    if (this.isRefreshingToken && httpError && httpError.status === 401 && req && req.url && req.url.endsWith('/auth/token')) {
      this.isRefreshingToken = false;
      this.logout();
      return throwError(httpError);
    }

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.authenticationService.refresh().pipe(
        switchMap((user: AuthUser) => {
          if (user != null && user.refreshToken) {
            this.tokenSubject.next(user.token);
            return next.handle(this.addToken(req, user.token));
          }
          this.logout();
          return throwError(() => httpError);
        }),
        catchError(() => {
          this.logout();
          return throwError(() => httpError);
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        })
      );
    }
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  handle403Error(error): Observable<never> {
    this.router.navigate(['/errors/403']).then();
    return throwError(error);
  }

  handle400Error(httpError): Observable<never> {
    let error = httpError.statusText;
    if (httpError.error != null) {
      if (httpError.error instanceof Blob) {
        error = new ApiBlobResponse(httpError.error);
      } else {
        error = plainToClass(ApiResponse, httpError.error);

        error.status = httpError.status;
      }
    }
    return throwError(error);
  }

  handle500Error(error): Observable<never> {
    this.alertService.error500();
    return throwError(error);
  }

  handle404Error(error): Observable<never> {
    this.alertService.error404();
    return throwError(error);
  }

  logout(): Observable<never> {
    /*this.authenticationService.logout();
    location.reload();
    return throwError('');*/
    return of();
  }
}
