import { BaseEntity } from 'src/app/core/domain/base.entity';
import { Natureza } from '../natureza/natureza.model';
import { TipoPessoa } from '../tipo-pessoa/tipo-pessoa';

export class ContaCorrente extends BaseEntity {
  codigo: number;
  descricao: string;
  tipoPessoa: TipoPessoa;
  natureza: Natureza;
  dataCadastro?: Date;
  dataAtualizacao?: Date;
}
