import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { LogService } from './core/services/log.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends LogService implements OnInit {
  menuMode = 'sidebar';

  layout = 'blue-tremt';

  theme = 'blue-tremt';

  ripple: boolean;

  colorScheme = 'light';

  constructor(public translate: TranslateService, private primengConfig: PrimeNGConfig) {
    super();
    translate.addLangs(['en', 'fr', 'pt']);
    translate.setDefaultLang('pt');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|pt/) ? browserLang : 'pt');
    translate.get('primeng').subscribe(res => this.primengConfig.setTranslation(res));
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.ripple = true;
  }
}
