import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { DevelopmentInterceptor } from './core/interceptors/development.interceptor';
import { JsonDateInterceptor } from './core/interceptors/date.interceptor';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LayoutComponent } from './layouts/layout.component';
import { AuthProvider } from './core/auth/auth.provider';
import { OauthProvider } from './core/auth/provider/oauth.provider';
import { ConfirmationService } from 'primeng/api';
import { EnvService } from './core/services/env.service';
import { PreferenceService } from './core/services/preference.service';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { NgxMaskModule } from 'ngx-mask';
import { AuditConfigModule } from './domain/audit/audit-config.module';

registerLocaleData(ptBr);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const appInitializerFn = (appConfig: EnvService) => {
  return () => {
    return appConfig.loadEnv();
  };
};

const appInitializerPreferences = (appConfig: PreferenceService) => {
  return () => {
    return appConfig.loadPreference();
  };
};

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          const userString = localStorage.getItem('loggedUser');
          const user = JSON.parse(userString);
          return user ? user.token : '';
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    AuditConfigModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    EnvService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [EnvService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerPreferences,
      multi: true,
      deps: [PreferenceService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DevelopmentInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: AuthProvider, useClass: OauthProvider },
    ConfirmationService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
