import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { ObjetoDespesa } from '../objeto-despesa/objeto-despesa.model';

const objetoDespesaAudit: ResourceConfig = {
  api: 'objetos-despesas',
  path: 'objetos-despesas',
  entity: ObjetoDespesa.name,
  label: 'Objeto Despesa',
  translateKey: 'objeto-despesa',
  showInPage: false,
  labelTemplate: `<u>Objeto Despesa '{descricao}'</u>`,
  columns: [
    { field: 'descricao', header: 'field.descricao', isDefaultVisible: true },
    {
      field: 'listaObjetoDespesaContaCorrente',
      listField: 'contaCorrente.descricao',
      header: 'field.listaObjetoDespesaContaCorrente',
      isDefaultVisible: true,
      isList: true,
      isNotExport: true
    },
    {
      field: 'dataCadastro',
      header: 'field.dataCadastro',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy HH:mm',
      formatOption: 'DATETIME'
    },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default objetoDespesaAudit;
