import { Pipe, PipeTransform } from '@angular/core';
import { SimNao } from 'src/app/domain/simNao/sim-nao';

@Pipe({ name: 'simNaoPipe' })
export class SimNaoPipe implements PipeTransform {
  transform(value: boolean | string): string {
    return (typeof value == 'string' && value === SimNao.SIM) || (typeof value == 'boolean' && value)
      ? SimNao.SIM_STRING
      : SimNao.NAO_STRING;
  }
}
