import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { ContaCorrente } from '../conta-corrente/conta-corrente.model';

const contaCorrenteAudit: ResourceConfig = {
  api: 'contas-correntes',
  path: 'contas-correntes',
  entity: ContaCorrente.name,
  label: 'Conta Correntes',
  translateKey: 'conta-corrente',
  showInPage: false,
  labelTemplate: `<u>Conta Corrente '{descricao}'</u>`,
  columns: [
    { field: 'codigo', header: 'field.codigo', isDefaultVisible: true },
    { field: 'descricao', header: 'field.descricao', isDefaultVisible: true },
    { field: 'tipoPessoa', header: 'field.tipoPessoa', isDefaultVisible: true },
    { field: 'natureza.descricao', header: 'field.natureza', isDefaultVisible: true },
    {
      field: 'dataCadastro',
      header: 'field.dataCadastro',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy HH:mm',
      formatOption: 'DATETIME'
    },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default contaCorrenteAudit;
