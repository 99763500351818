import { Finalidade } from './../finalidade/finalidade.model';
import { BaseEntity } from 'src/app/core/domain/base.entity';

export class Portaria extends BaseEntity {
  descricao: string;
  prazoAplicacao: number;
  prazoPrestacaoContas: number;
  artigosPrazoAplicacao: string;
  listaFinalidadesPortaria: Finalidade[];
  ativo: boolean;
  dataCadastro: string;
  dataAtualizacao: string;
}
