import { TipoDespesa } from './../tipo-despesa/tipo-despesa';
import { ContaCorrente } from '../conta-corrente/conta-corrente.model';
import { Fornecedor } from './../fornecedor/fornecedor.model';
import { BaseEntity } from 'src/app/core/domain/base.entity';
import { Suprimento } from '../suprimento/suprimento.model';
import { ObjetoDespesa } from '../objeto-despesa/objeto-despesa.model';
import { ObjetoDespesaContaCorrente } from '../objeto-despesa/objeto-despesa-conta-corrente';
import { ItemDespesa } from '../item-despesa/item-despesa';

export class Despesa extends BaseEntity {
  fornecedor: Fornecedor;
  finalidade: string;
  dataNf: Date;
  numeroNf: string;
  tipoDespesa: TipoDespesa;
  suprimento: Suprimento;
  ativo: boolean;
  dataCadastro: Date;
  dataAtualizacao: Date;
  listaItemDespesa: ItemDespesa[];
}
