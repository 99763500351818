import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { Natureza } from '../natureza/natureza.model';

const naturezaAudit: ResourceConfig = {
  api: 'naturezas',
  path: 'naturezas',
  entity: Natureza.name,
  label: 'Natureza',
  translateKey: 'natureza',
  showInPage: false,
  labelTemplate: `<u>Natureza '{descricao}'</u>`,
  columns: [
    { field: 'codigo', header: 'field.codigo', isDefaultVisible: true },
    { field: 'descricao', header: 'field.descricao', isDefaultVisible: true },
    { field: 'elemento', header: 'field.elemento', isDefaultVisible: true },
    {
      field: 'dataCadastro',
      header: 'field.dataCadastro',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy HH:mm',
      formatOption: 'DATETIME'
    },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default naturezaAudit;
