import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { AcaoOrcamentaria } from '../acao-orcamentaria/acao-orcamentaria.model';

const acaoOrcamentariaAudit: ResourceConfig = {
  api: 'acoes-orcamentarias',
  path: 'acoes-orcamentarias',
  entity: AcaoOrcamentaria.name,
  label: 'Ação Orçamentaria',
  translateKey: 'acaoorcamentaria',
  showInPage: false,
  labelTemplate: `<u>Ação Orçamentaria '{descricao}'</u>`,
  columns: [
    { field: 'descricao', header: 'field.descricao', isDefaultVisible: true },
    { field: 'controlaCc', header: 'field.controlaCc', isDefaultVisible: true },
    {
      field: 'dataCadastro',
      header: 'field.dataCadastro',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy HH:mm',
      formatOption: 'DATETIME'
    },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default acaoOrcamentariaAudit;
