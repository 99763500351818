import { Component, OnDestroy } from '@angular/core';
import { LoadingService } from 'src/app/domain/loading/loading.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loading',
  template: '<ngx-loading [show]="loading | async"></ngx-loading>'
})
export class LoadingComponent {
  public loading: Subject<boolean> = this.loadingService.loading;

  constructor(public loadingService: LoadingService) {}
}
