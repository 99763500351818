export default class Utils {
  static replaceAll(str: string, find: string, replace: string): string {
    const escapedFind = find.replace('/([.*+?^=!:${}()|[]/\\])/g', '\\$1');
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  static existsLongWordForDescricao(word: string, maxLenght: number): boolean {
    const words: string[] = word.split(' ');
    let isLongWord = false;

    words.forEach(value => {
      if (value.length >= maxLenght) {
        isLongWord = true;
      }
    });

    return isLongWord;
  }
}
