import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emptyDataPipe' })
export class EmptyDataPipe implements PipeTransform {
  transform(value: any, checkEmpty?: boolean): any {
    if (value == null || (checkEmpty && value === '')) {
      //TODO: Adicionar texto no i18n
      return 'Valor não informado';
    }
    return value;
  }
}
