import { Filter } from './../api/filter/filter.model';
import { Observable } from 'rxjs';
import { BaseEntity } from './base.entity';
import { Pagination } from '../api/pagination';
import { HttpClient } from '@angular/common/http';
import { ApiListResponse } from '../api/apiListResponse';
import { EnvService } from '../services/env.service';
import { Sort } from '../api/sort/sort.model';
import Utils from 'src/app/shared/util/util';

export abstract class BaseService<T extends BaseEntity, ID> {
  protected envService: EnvService;
  protected http: HttpClient;
  protected api: string;
  protected searchStrategy;

  protected constructor(http: HttpClient, envService: EnvService, api: string) {
    this.http = http;
    this.api = api;
    this.envService = envService;
  }

  paginate(pagination: Pagination): Observable<ApiListResponse<T>> {
    return this.http.get<ApiListResponse<T>>(`${this.getBaseUrl()}`, { params: pagination.getParams() });
  }

  exportaDataToFile(type: string, titleFile: string, filter: Filter, configuracao: string): Observable<Blob> {
    const parameters = {
      title: titleFile,
      ...filter?.params
    };

    return this.http.post(`${this.getBaseUrl()}/export/${type}`, configuracao, {
      params: parameters,
      responseType: 'blob'
    });
  }

  findList(searchQuery: string, sortQuery: Sort[], maxRegistros: number = null): Observable<T[]> {
    if (searchQuery != null && searchQuery.length > 0) {
      searchQuery = searchQuery.trim();
      searchQuery = Utils.replaceAll(searchQuery, '  ', ' ');
      searchQuery = Utils.replaceAll(searchQuery, ' ', '*');
    }
    const parameters = {
      search: searchQuery,
      maxRegistros: maxRegistros
    };

    if (sortQuery && sortQuery.length > 0) {
      parameters['sort'] = sortQuery.map(x => x.field + ',' + x.order);
    }

    return this.http.get<T[]>(`${this.getBaseUrl()}/find-list`, { params: parameters });
  }

  get(id: ID): Observable<T> {
    return this.http.get<T>(`${this.getBaseUrl()}/${id}`);
  }

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(`${this.getBaseUrl()}/all`);
  }

  getAllSort(sortQuery: Sort[]): Observable<T[]> {
    const parameters = {};
    if (sortQuery && sortQuery.length > 0) {
      parameters['sort'] = sortQuery.map(x => x.field + ',' + x.order);
    }

    return this.http.get<T[]>(`${this.getBaseUrl()}/all`, { params: parameters });
  }

  remove(id: ID): Observable<T> {
    return this.http.delete<T>(`${this.getBaseUrl()}/${id}`);
  }

  updateStatus(id: ID): Observable<T> {
    return this.http.post<T>(`${this.getBaseUrl()}/update-status/${id}`, null);
  }

  create(entity: T): Observable<T> {
    return this.http.post<T>(this.getBaseUrl(), {
      ...entity
    });
  }

  update(entity: T): Observable<T> {
    return this.http.put<T>(`${this.getBaseUrl()}/${entity.id}`, {
      ...entity
    });
  }

  getBaseUrl(): string {
    return `${this.envService.environment.baseUrl}/${this.api}`;
  }

  getHttp(): HttpClient {
    return this.http;
  }
}
