import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { FormaConcessao } from '../forma-concessao/forma-concessao.model';

const formaConcessaoAudit: ResourceConfig = {
  api: 'concessoes',
  path: 'concessoes',
  entity: FormaConcessao.name,
  label: 'Forma de Concessões',
  translateKey: 'forma-concessao',
  showInPage: false,
  labelTemplate: `<u>Forma Concessão '{descricao}'</u>`,
  columns: [
    { field: 'descricao', header: 'field.descricao', isDefaultVisible: true },
    { field: 'exigeContaCorrente', header: 'field.exigeContaCorrente', isDefaultVisible: true },
    {
      field: 'dataCadastro',
      header: 'field.dataCadastro',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy HH:mm',
      formatOption: 'DATETIME'
    },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default formaConcessaoAudit;
