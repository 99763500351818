import { Directive, ElementRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { ResourceConfig } from '../model/resource-config.model';
import { BaseEntity } from '../../domain/base.entity';
import { AuditConfigLocator, IAuditConfigLocator } from '../audit.config';
import { fromEvent, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Directive({
  selector: '[appAuditRouter]'
})
export class AuditRouterDirective implements OnDestroy {
  private auditConfig: ResourceConfig;
  private entity: BaseEntity;
  private clickSubscription: Subscription;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<void>,
    private viewContainer: ViewContainerRef,
    private router: Router,
    private auditConfigLocator: IAuditConfigLocator
  ) {}

  @Input()
  set appAuditRouter(entity: BaseEntity) {
    this.entity = entity;
    this.auditConfig = this.auditConfigLocator.get(Object.getPrototypeOf(entity).constructor.name);
    this.updateView();
  }

  private updateView(): void {
    if (this.auditConfig != null) {
      const view = this.viewContainer.createEmbeddedView(this.templateRef);
      this.clickSubscription = fromEvent(view.rootNodes[0], 'click').subscribe(() => {
        this.router.navigate([this.routerLink], { queryParams: { returnUrl: this.router.url } }).then();
      });
    } else {
      this.viewContainer.clear();
    }
  }

  get routerLink(): string {
    if (this.auditConfig != null) {
      return `/auditoria/${this.auditConfig.path}/${this.entity.id}`;
    }
    return null;
  }

  ngOnDestroy(): void {
    if (this.clickSubscription != null) {
      this.clickSubscription.unsubscribe();
    }
  }
}
