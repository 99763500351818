<div class="layout-breadcrumb-container p-d-flex p-ai-center p-jc-between">
  <div class="layout-breadcrumb-left-items p-d-flex p-ai-center">
    <a href="#" class="menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isStatic() && !appMain.isMobile()">
      <i class="pi pi-bars"></i>
    </a>

    <p-breadcrumb styleClass="layout-breadcrumb p-py-2"></p-breadcrumb>

    <div class="layout-breadcrumb p-py-2 p-breadcrumb p-component" ng-reflect-ng-class="p-breadcrumb p-component" style="padding-left: 0">
      <ul>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
          <li class="ng-star-inserted">
            <a class="p-menuitem-link ng-star-inserted" [routerLink]="item.routerLink" *ngIf="item.routerLink">
              {{item.label.toUpperCase() | translate}}
            </a>
            <ng-container *ngIf="!item.routerLink">
              <span class="p-menuitem-link ng-star-inserted">{{item.label | translate}} </span>
            </ng-container>
          </li>
          <li *ngIf="!last" class="p-breadcrumb-chevron pi pi-chevron-right ng-star-inserted"></li>
        </ng-template>
      </ul>
    </div>
  </div>
</div>
