import { AuthorizationCodeCallback } from '../model/authorization-code.model';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as crypto from 'crypto-js';
import { encode as base64encode } from 'base64-arraybuffer';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LogService } from '../../services/log.service';
import { AuthProvider } from '../auth.provider';
import { AuthToken } from '../model/token.model';
import { AuthUser } from '../model/user.model';
import { EnvService } from '../../services/env.service';
import { v4 as uuidv4 } from 'uuid';
import { AuthLogout } from '../model/logout.model';

@Injectable()
export class OauthProvider extends AuthProvider {
  constructor(
    private router: Router,
    private http: HttpClient,
    private logService: LogService,
    private jwtHelper: JwtHelperService,
    private envService: EnvService
  ) {
    super();
  }

  login(username: string, password: string): Observable<AuthToken> {
    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('client_id', this.environment.authClientId);
    body.set('username', username);
    body.set('password', password);

    return this.http.post<AuthToken>(this.environment.authLoginUrl, body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  logout(authUser: AuthUser): Observable<AuthLogout> {
    if (authUser != null) {
      const body = new URLSearchParams();
      body.set('idToken', authUser.idToken);
      body.set('refreshToken', authUser.refreshToken);
      return this.http.post<AuthLogout>(this.environment.authLogoutUrl, body.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      });
    }
    return of();
  }

  refresh(refreshToken: string): Observable<AuthToken> {
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', this.environment.authClientId);
    body.set('refresh_token', refreshToken);

    return this.http.post<AuthToken>(this.environment.authLoginUrl, body.toString(), {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  authorize(): void {
    this.redirectAuthorizationPage().then();
  }

  async redirectAuthorizationPage(): Promise<void> {
    const verifierCode =
      'EDXYwLVlNb8dcnYmBKdD35h9cwxGoC4EG191tCgqzMu6BuzCdB61vlWrZvtEup6aR4GjMFjQLgvO6edDBFCeW7eIXz9zMXxV6p3Y2E4NTS8aVLcbm1HgWfoq';

    const authChallenge = this.environment.authChallenge;
    const authNonce = this.environment.authNonce;
    let authAuthorizationResponseType = this.environment.authAuthorizationResponseType;
    const authAuthorizationSecretKey = this.environment.authAuthorizationSecretKey;
    if (authAuthorizationResponseType == null) {
      authAuthorizationResponseType = 'code';
    }

    const payload = new URLSearchParams();
    payload.append('client_id', this.environment.authClientId);
    if (authAuthorizationSecretKey) {
      payload.append('client_secret', this.environment.authClientSecret);
    }
    payload.append('state', verifierCode);
    payload.append('scope', this.environment.authScope);
    payload.append('response_type', authAuthorizationResponseType);
    payload.append('redirect_uri', this.environment.authCallbackUrl);

    if (authChallenge) {
      const codeChallenge = await OauthProvider.generateCodeChallenge(verifierCode);
      payload.append('code_challenge', codeChallenge);
      payload.append('code_challenge_method', 'S256');
    }

    if (authNonce) {
      payload.append('nonce', uuidv4());
    }

    payload.append('grant_type', 'authorization_code');
    window.location.href = `${this.environment.authCodeUrl}?${payload.toString()}`;
  }

  public callback(authorizationCode: AuthorizationCodeCallback): Observable<AuthToken> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const payload = new URLSearchParams();

    const authBasicAuthorization = this.environment.authBasicAuthorization;
    const authSendClientIdClientSecret = this.environment.authSendClientIdClientSecret;

    payload.append('grant_type', 'authorization_code');
    payload.append('code', authorizationCode.code);
    payload.append('redirect_uri', this.environment.authCallbackUrl);

    if (authBasicAuthorization === true) {
      const authorizationHeader = btoa(`${this.environment.authClientId}:${this.environment.authClientSecret}`);
      headers = headers.append('Authorization', `Basic ${authorizationHeader}`);
    }

    if (authSendClientIdClientSecret) {
      payload.append('client_id', this.environment.authClientId);
      payload.append('client_secret', this.environment.authClientSecret);
      payload.append('code_verifier', authorizationCode.state);
    }

    return this.http.post<AuthToken>(this.environment.authLoginUrl, payload.toString(), { headers });
  }

  processToken(authToken: AuthToken): AuthUser {
    const jwtToken = this.jwtHelper.decodeToken(authToken.access_token);
    return {
      id: jwtToken.sub,
      email: authToken.email ?? jwtToken.email,
      name: authToken.name ?? jwtToken.name,
      token: authToken.access_token,
      refreshToken: authToken.refresh_token,
      idToken: authToken.id_token,
      permissions: authToken.permissions ?? [],
      roles: authToken.roles ?? [],
      titulo: authToken.titulo ?? '',
      usuarioDTO: authToken.usuarioDTO ?? null
    };
  }

  private static generateRandomCode(): string {
    return crypto.lib.WordArray.random(32).toString();
  }

  private static generateVerifierCode(): string {
    return OauthProvider.base64URLEncode(crypto.lib.WordArray.random(32).toString());
  }

  private static async generateCodeChallenge(verifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(verifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const base64Digest = base64encode(digest);
    return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  private static base64URLEncode(str): string {
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  private get environment() {
    return this.envService.environment;
  }
}
