import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const loggedUser = this.authenticationService.loggedUser();
    if (loggedUser && this.authenticationService.isAuthenticated()) {
      return true;
    }
    this.authenticationService.logout();
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } }).then();
    return false;
  }
}
