import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() icon?: string;
  @Input() label: string;
  @Input() colorClass: string;
  @Input() accept?: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Input() maxFileSize?: number;

  @ViewChild('fileUploadNative')
  fileUploadNative: ElementRef;

  get acceptFiles(): string {
    return this.accept == null ? '*' : this.accept;
  }

  onUploadDocument(event): void {
    let hasValid = true;
    for (const file of event.target.files) {
      if (!this.validate(file)) {
        hasValid = false;
        break;
      }
    }
    if (hasValid) {
      this.onUpload.emit(event.target.files);
    }
    this.fileUploadNative.nativeElement.value = '';
  }

  overflowMaxFileSize(file: File): boolean {
    return file.size >= this.maxFileSize * 1024 * 1024;
  }

  onClick(): void {
    this.fileUploadNative.nativeElement.click();
  }

  validate(file: File): boolean {
    if (this.accept && !this.isFileTypeValid(file)) {
      this.onError.emit(`O formato do arquivo ${file.name} não é permitido. Arquivos aceitos: ${this.accept}`);
      return false;
    }

    if (this.maxFileSize && file.size >= this.maxFileSize * 1024 * 1024) {
      this.onError.emit(`O arquivo ${file.name} ultrapassou o tamanho máximo de ${this.maxFileSize} MB`);
      return false;
    }

    return true;
  }

  private isFileTypeValid(file: File): boolean {
    const acceptableTypes = this.accept.split(',').map(type => type.trim());
    for (const type of acceptableTypes) {
      const acceptable = this.isWildcard(type)
        ? this.getTypeClass(file.type) === this.getTypeClass(type)
        : file.type === type || this.getFileExtension(file).toLowerCase() === type.toLowerCase();
      if (acceptable) {
        return true;
      }
    }
    return false;
  }

  getTypeClass(fileType: string): string {
    return fileType.substring(0, fileType.indexOf('/'));
  }

  isWildcard(fileType: string): boolean {
    return fileType.indexOf('*') !== -1;
  }

  getFileExtension(file: File): string {
    return '.' + file.name.split('.').pop();
  }
}
