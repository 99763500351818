import { ResourceConfig } from '../../core/audit/model/resource-config.model';
import { Portaria } from '../portaria/portaria.model';

const portariaAudit: ResourceConfig = {
  api: 'portarias',
  path: 'portarias',
  entity: Portaria.name,
  label: 'Portaria',
  translateKey: 'portaria',
  showInPage: false,
  labelTemplate: `<u>Portaria '{descricao}'</u>`,
  columns: [
    { field: 'descricao', header: 'field.descricao', isDefaultVisible: true },
    {
      field: 'dataCadastro',
      header: 'field.dataCadastro',
      isDefaultVisible: true,
      isDate: true,
      mask: 'dd/MM/yyyy HH:mm',
      formatOption: 'DATETIME'
    },
    { field: 'ativo', header: 'field.ativo', isDefaultVisible: true, isStatus: true, formatOption: 'BOOLEAN' }
  ]
};

export default portariaAudit;
