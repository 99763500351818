<div class="file-upload-container {{colorClass}}" (click)="onClick()">
  <svg-icon *ngIf="icon !== null" src="/assets/images/icons/{{icon}}" svgClass="svg-icon {{colorClass}}"></svg-icon>
  <span>{{label}}</span>
  <input
    #fileUploadNative
    style="display: none"
    [accept]="acceptFiles"
    name="file-upload"
    type="file"
    (change)="onUploadDocument($event)"
  />
</div>
